import axios from "axios";
import { store } from "../redux/storeConfig/store";
import { handleClearCache } from "../redux/actions/cache/index";

const headers = {};
if (sessionStorage.getItem("accessToken"))
  headers.Authorization = `Bearer ${sessionStorage.getItem("accessToken")}`;

const baseURL = window._env_.REACT_APP_BASE_URL;

export const instance = axios.create({
  baseURL,
  timeout: 10000,
  headers,
});

const createAxiosResponseInterceptor = () => {
  const interceptor = instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        sessionStorage.getItem("userData") &&
        sessionStorage.getItem("accessToken") &&
        sessionStorage.getItem("refreshToken")
      ) {
        if (error.response?.status !== 401) {
          // Reject promise if usual error
          return Promise.reject(error);
        }

        instance.interceptors.response.eject(interceptor);
        const params = new URLSearchParams();
        params.append("refresh_token", sessionStorage.getItem("refreshToken"));
        params.append(
          "client_id",
          window._env_.REACT_APP_AUTH0_SERVER_CLIENT_SECRET
        );
        params.append("grant_type", "refresh_token");
        params.append(
          "client_secret",
          window._env_.REACT_APP_AUTH0_SERVER_CLIENT_SECRET
        );
        params.append("audience", window._env_.REACT_APP_AUTH0_AUDIENCE);
        params.append("scope", "offline_access");

        return instance
          .post("/oauth/token", params, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            baseURL: window._env_.REACT_APP_AUTH0_ISSUER_BASE_URL,
          })
          .then((response) => {
            sessionStorage.setItem("accessToken", response.data.access_token);
            sessionStorage.setItem("refreshToken", response.data.refresh_token);

            error.response.config.headers["Authorization"] =
              "Bearer " + response.data.access_token;
            return instance(error.response.config);
          })
          .catch((err) => {
            // handle user logout
            // if browser tab has been inactive for more than 30 minutes and the refresh token has not been updated
            sessionStorage.clear();
            // clear cache
            store.dispatch(handleClearCache());
            let _redirectUrl = "/login";
            if (window.location.href.includes("?")) {
              _redirectUrl = `/login?redirect=${window.btoa(
                `${window.location.pathname}?${
                  window.location.href.split("?")[1]
                }`
              )}`;
            } else {
              // console.log(window.location.pathname);
              _redirectUrl = `/login?redirect=${window.btoa(
                window.location.pathname
              )}`;
            }
            window.location.href = _redirectUrl;
            return Promise.reject(err);
          })
          .finally(createAxiosResponseInterceptor);
      }
    }
  );
};
createAxiosResponseInterceptor();
// export default instance;
